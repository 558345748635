import React, { useContext } from 'react';
import { Button } from '@material-ui/core';
import { NavHashLink as NavLink } from 'react-router-hash-link';
import { makeStyles } from '@material-ui/core/styles';

import './Landing.css';
import { ThemeContext } from '../../contexts/ThemeContext';
import Spinner from '../spinner/Spinner';  // Corrected import statement
import { headerData } from '../../data/headerData';
import { socialsData } from '../../data/socialsData';

// Dynamically import icons to reduce initial load
const FaTwitter = React.lazy(() => import('react-icons/fa').then(module => ({ default: module.FaTwitter })));
const FaLinkedin = React.lazy(() => import('react-icons/fa').then(module => ({ default: module.FaLinkedin })));
const FaGithub = React.lazy(() => import('react-icons/fa').then(module => ({ default: module.FaGithub })));
const FaGoogle = React.lazy(() => import('react-icons/fa').then(module => ({ default: module.FaGoogle })));
const FaInstagram = React.lazy(() => import('react-icons/fa').then(module => ({ default: module.FaInstagram })));

const useStyles = makeStyles((theme) => ({
    resumeBtn: {
        color: theme.primary,
        borderRadius: '30px',
        textTransform: 'inherit',
        textDecoration: 'none',
        width: '150px',
        fontSize: '1rem',
        fontWeight: '500',
        height: '50px',
        fontFamily: 'var(--primaryFont)',
        border: `3px solid ${theme.primary}`,
        transition: '100ms ease-out',
        '&:hover': {
            backgroundColor: theme.tertiary,
            color: theme.secondary,
            border: `3px solid ${theme.tertiary}`,
        },
        [theme.breakpoints.down('sm')]: {
            width: '180px',
        },
    },
    contactBtn: {
        backgroundColor: 'rgb(84, 95, 196)', // Set to rgb(84, 95, 196)
        color: 'white',                     // Set to white or any desired color
        borderRadius: '30px',
        textTransform: 'inherit',
        textDecoration: 'none',
        width: '150px',
        height: '50px',
        fontSize: '1rem',
        fontWeight: '500',
        fontFamily: 'var(--primaryFont)',
        border: `3px solid rgb(84, 95, 196)`, // Set to rgb(84, 95, 196)
        transition: '100ms ease-out',
        '&:hover': {
            backgroundColor: 'white',          // Change to the desired hover background color
            color: 'rgb(84, 95, 196)',         // Change to the desired hover text color
            border: `3px solid rgb(84, 95, 196)`, // Set to rgb(84, 95, 196)
        },
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        },
    },
}));

const SocialIcon = ({ href, IconComponent, label, theme }) => (
    <a href={href} target='_blank' rel="noopener noreferrer">
        <IconComponent className='landing--social' style={{ color: theme.secondary }} aria-label={label} />
    </a>
);

function Landing() {
    const { theme, drawerOpen } = useContext(ThemeContext);
    const classes = useStyles(theme);

    return (
        <div className='landing'>
            <div className='landing--container'>
                <div
                    className='landing--container-left'
                    style={{ backgroundColor: theme.primary }}
                >
                    <div className='lcl--content'>
                        <React.Suspense fallback={<Spinner/>}>
                            {socialsData.linkedIn && (
                                <SocialIcon href={socialsData.linkedIn} IconComponent={FaLinkedin} label='LinkedIn' theme={theme} />
                            )}
                            {socialsData.github && (
                                <SocialIcon href={socialsData.github} IconComponent={FaGithub} label='GitHub' theme={theme} />
                            )}
                            {socialsData.twitter && (
                                <SocialIcon href={socialsData.twitter} IconComponent={FaTwitter} label='Twitter' theme={theme} />
                            )}
                            {socialsData.gmail && (
                                <SocialIcon href={socialsData.gmail} IconComponent={FaGoogle} label='Google' theme={theme} />
                            )}
                            {socialsData.instagram && (
                                <SocialIcon href={socialsData.instagram} IconComponent={FaInstagram} label='Instagram' theme={theme} />
                            )}
                        </React.Suspense>
                    </div>
                </div>
                <img
                    src={headerData.image}
                    alt=''
                    className='landing--img'
                    style={{
                        opacity: drawerOpen ? '0' : '1',
                        borderColor: theme.secondary,
                    }}
                />
                <div
                    className='landing--container-right'
                    style={{ backgroundColor: theme.secondary }}
                >
                    <div className='lcr--content' style={{ color: theme.tertiary }}>
                        <h6>{headerData.title}</h6>
                        <h1>{headerData.name}</h1>
                        <p>{headerData.desciption}</p>

                        <div className='lcr-buttonContainer'>
                            {headerData.resumePdf && (
                                <a
                                    href={headerData.resumePdf}
                                    download='resume'
                                    target='_blank'
                                    rel="noopener noreferrer"
                                >
                                    <Button className={classes.resumeBtn}>
                                        Download CV
                                    </Button>
                                </a>
                            )}
                            <NavLink
                                to='/#contacts'
                                smooth={true}
                                spy={true}
                                duration={2000}
                            >
                                <Button className={classes.contactBtn}>
                                    Contact
                                </Button>
                            </NavLink>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default React.memo(Landing);
