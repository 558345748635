export const educationData = [
    {
        id: 3,
        institution: 'Mangalore University',
        course: 'MCA - Master of Computer Applications,',
        // startYear: '2019',
        // endYear: '2021'
    },
    {
        id: 1,
        institution: 'Kannur University ',

        course: 'BSc CS - Bachelor of Science in Computer Science,',
        // startYear: '2015',
        // endYear: '2018'
    },
    // {
    //     id: 2,
    //     institution: 'Mangalore University',
    //     course: 'Post Graduate Diploma in Computer Applications,',
    //     startYear: '2018',
    //     endYear: '2019'
    // },
   
]