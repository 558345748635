import one from '../assets/svg/projects/Green Leaves - Thumbnail2.jpg'
import two from '../assets/svg/projects/StudyOnline-Thumbnail2.jpg'
import three from '../assets/svg/projects/Code-Converter-Thumbnail2.jpg'

export const projectsData = [
    {
        id: 1,
        projectName: 'Green Leaves',
        projectDesc: 'E-commerce website to purchase decorative plants, The website includes features such as add to cart, wishlist, order history, cash on delivery,coupons, wallet, user profile, return products',
        tags: ['Bootrap', 'MongoDB', 'Express.js'],
        code: 'https://github.com/krishnaprasad45/Green_Leaf',
        // demo: 'https://greenleaves.shop/',
        image: one
    },
    {
        id: 2,
        projectName: 'Study Online',
        projectDesc: 'StudyOnline is a platform designed to make coding education accessible for individuals who face challenges attending institutions or struggle with self-learning.',
        tags: ['React','TypeScript','MongoDB','Express.js'],
        code: 'https://github.com/krishnaprasad45/StudyOnline-Client-Redux-React-TypeScript-Tailwind-',
        // demo: 'https://react-study-online.vercel.app/',
        image: two
    },
    {
        id: 3,
        projectName: 'Code Converter',
        projectDesc: 'Effortlessly translating code into different programming languages! Whether you are switching languages or just aiming for better comprehension, this tool has got you covered' ,
        tags: ['React', 'Vite' ,'OpenAI api'],
        code: 'https://github.com/krishnaprasad45/Code-Converter',
        // demo: 'https://code-converter-seven-rose.vercel.app/',
        image: three
    },
    
]


