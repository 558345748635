export const toolsData = [
    'Figma',
    'ViteJS',
    'Git',
    'AWS',
    'MaterialUI',
    'Nginx',

    
]

// Choose your skills from below. Make sure it's in the same format and spelled correctly.
// Couldn't find the required skills? Raise an issue on github at https://github.com/hhhrrrttt222111/developer-portfolio/issues/new


// AVAILABLE SKILLS

/* 
   
    Photoshop
    Illustrator
    Svelte
    GCP
    Azure
    Fastify
    Haxe
    Ionic
    Markdown
    Microsoft Office
    Picsart
    Sketch
    WolframAlpha
    Adobe XD
    After Effects
    Bulma
    CapacitorJs
    Coffeescript
    MemSQL
    Julia
    Git
    Figma
    Canva
    Ubuntu
    ViteJS
    VuetifyJS
    AWS
    Blender
    Premiere Pro
    Adobe Audition
    Deno
    Gimp
    Lightroom
    MaterialUI
    Nginx
    Numpy
    OpenCV
    Pytorch
    Selenium
    Strapi
    Tensorflow
    Webex
    Wordpress
*/
